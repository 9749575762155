var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{ stickyHeader: _vm.$vuetify.breakpoint.lgAndUp, stickyHeaderSmall: !_vm.$vuetify.breakpoint.lgAndUp }},[_c('v-row',{staticStyle:{"margin":"0"},attrs:{"align":"center","justify":"center"}},[_c('v-select',{staticStyle:{"width":"150px","margin-right":"30px"},attrs:{"prepend-icon":_vm.selectedType ? _vm.selectedType.icon : '',"prepend-inner":"","items":_vm.types,"label":"Collection","item-text":"label","item-value":"key"},on:{"change":function($event){return _vm.changeCollection($event)}}}),_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"prepend-icon":"fa-search","placeholder":"Rechercher"},on:{"change":function($event){return _vm.applyFilter()}},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"disabled":_vm.isLoading}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{on:{"click":function($event){$event.stopPropagation();return _vm.downloadAsCsv()}}},[_c('v-icon',[_vm._v("fas fa-file-download")])],1)]}}])},[_c('span',[_vm._v("Télécharger les données au format CSV (excel)")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{on:{"click":function($event){$event.stopPropagation();return _vm.displayStats()}}},[_c('v-icon',[_vm._v("fas fa-chart-pie")])],1)]}}])},[_c('span',[_vm._v("Voir les statistiques")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{on:{"click":function($event){$event.stopPropagation();return _vm.resetDialog(true)}}},[_c('v-icon',[_vm._v("fas fa-plus")])],1)]}}])},[_c('span',[_vm._v("Enregistrer une nouvelle photo")])])],1)],1)],1),_c('v-container',[_c('v-expansion-panels',{staticClass:"albumCollection",attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.displayedCollections),function(c){return _c('v-expansion-panel',{key:c.id},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(c.title)+" "),_c('v-spacer'),_vm._v(" "+_vm._s(c.count)+"/"+_vm._s(c.total)+" "),_c('i',{staticClass:"fas fa-circle",class:c.cssStatus,staticStyle:{"flex":"none"}})],1),_c('v-expansion-panel-content',_vm._l((c.items),function(i){return _c('v-tooltip',{key:i.number,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:i.ok ? 'itemOk' : 'itemGhost',style:({ 'background-image': ("url('" + (i.img) + "')") }),on:{"click":function($event){return _vm.switchItem(i)}}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(i.number)+" - "+_vm._s(i.title))])])}),1)],1)}),1)],1),_c('v-dialog',{attrs:{"width":"600px"},model:{value:(_vm.serieEditor.open),callback:function ($$v) {_vm.$set(_vm.serieEditor, "open", $$v)},expression:"serieEditor.open"}},[_c('v-card',[_c('v-card-title',{staticClass:"grey lighten-4 py-4 title"},[_vm._v(" Ajouter une nouvelle série à votre collection ")]),_c('v-container',{staticClass:"pa-4",attrs:{"grid-list-sm":""}},[_c('v-combobox',{attrs:{"items":_vm.serieEditor.availableSeries,"label":"Titre de la série","prepend-icon":"fas fa-tag","item-text":"title","disabled":_vm.serieEditor.isLoading},on:{"change":function($event){return _vm.onSerieTitleChanged()}},model:{value:(_vm.serieEditor.title),callback:function ($$v) {_vm.$set(_vm.serieEditor, "title", $$v)},expression:"serieEditor.title"}}),_c('v-data-table',{directives:[{name:"sortable-data-table",rawName:"v-sortable-data-table"}],attrs:{"headers":_vm.serieEditor.headers,"items":_vm.serieEditor.items,"item-key":"number"},on:{"sorted":_vm.saveOrder},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [_c('tbody',_vm._l((items),function(item,idx,k){return _c('tr',{key:idx},_vm._l((headers),function(header,key){return _c('td',{key:key},[_c('v-edit-dialog',{attrs:{"return-value":item[header.value],"large":""},on:{"update:returnValue":function($event){return _vm.$set(item, header.value, $event)},"update:return-value":function($event){return _vm.$set(item, header.value, $event)},"save":_vm.save,"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":""},model:{value:(item[header.value]),callback:function ($$v) {_vm.$set(item, header.value, $$v)},expression:"item[header.value]"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item[header.value])+" ")])],1)}),0)}),0)]}}])})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.serieEditor.isLoading},on:{"click":function($event){return _vm.addItem()}}},[_vm._v(" Ajouter item ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.serieEditor.isLoading},on:{"click":function($event){return _vm.resetDialog()}}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"accent","disabled":_vm.serieEditor.isLoading},on:{"click":function($event){return _vm.addNewCollecion()}}},[_vm._v(" Enregistrer ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }